import { Form, Formik, FormikHelpers } from 'formik'
import { BackLink, Button, Heading, Spinner } from 'govuk-react'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import {
  DrsDocumentIdentifierType,
  DrsSearchType,
  useValidateDrsSearchLazyQuery
} from '../../graphql/generated/schema'
import { CreateBundleFormData } from '../../types'
import { attachFocusToFirstFormElementError } from '../../utils/formikHelper'
import { ErrorSummaryRetry } from '../ErrorSummaryRetry'
import { InputField } from '../InputField'
import { RadioField } from '../RadioField'
import styles from './GatherDrsDocumentsForm.module.scss'

const NINO_REGEX = new RegExp(
  /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)?$/i
)
const CUSTOMER_REFERENCE_NUMBER_REGEX = new RegExp(/^[A-Za-z0-9-]{0,36}$/)
const CLAIM_REFERENCE_NUMBER_REGEX = new RegExp(/^[A-Za-z0-9\\-]{0,30}$/)

export interface GatherDrsDocumentsFormProps {
  /** Initial form data. */
  data: CreateBundleFormData
  /** Function to invoke when going back to the previous step in the form. */
  prev: (data: CreateBundleFormData) => void
  /** Function to invoke when submitting the form. */
  next: (data: CreateBundleFormData, final?: boolean, current?: number) => void
  /** Key if part of multi-step form */
  key?: number
  /** Flag to toggle loading state. */
  isSubmitLoading?: boolean
}

const formErrorMessages = {
  noDocuments: 'There are no valid documents in DRS for this search identifier',
  unknownSearchIdentifier:
    'The search identifier you have provided does not exist in DRS',
  drsUnavailable: 'DRS Search Service is unavailable',
  drsRequired: 'Select if you require DRS',
  drsSearchTypeRequired: 'Date option cannot be blank',
  invalidNino: 'The National Insurance Number you have provided is invalid',
  invalidCustomerRefNum:
    'The Customer Reference Number you have provided is invalid',
  invalidClaimRefNum: 'The Claim Reference Number you have provided is invalid',
  selectIdentifier: 'Choose a DRS search identifier'
}

/** Formik form allowing user to select a DRS search identifier and enter an associated value with the option selected.
 * This data will then be used download documents from DRS.
 */
export const GatherDrsDocumentsForm: React.FunctionComponent<
  GatherDrsDocumentsFormProps
> = ({ data, prev, next, isSubmitLoading = false }) => {
  const [
    validateDrsSearchQuery,
    { error: validateDrsSearchError, loading: validateDrsSearchLoading }
  ] = useValidateDrsSearchLazyQuery()

  const [drsSearchType, setDrsSearchType] = useState<DrsSearchType | null>(null)

  const validateDrsSearchValue = async (
    regex: RegExp,
    createError: (params?: yup.CreateErrorOptions) => yup.ValidationError,
    path: string,
    drsDocumentIdentifierType: DrsDocumentIdentifierType,
    drsDocumentIdentifierValue: string
  ) => {
    if (
      drsDocumentIdentifierValue !== '' &&
      drsDocumentIdentifierValue &&
      regex.test(drsDocumentIdentifierValue)
    ) {
      const drsDocumentIdentifierValueUpperCase =
        drsDocumentIdentifierValue.toUpperCase()

      const {
        data: validateDrsSearchData,
        error: validateDrsSearchValidationError
      } = await validateDrsSearchQuery({
        variables: {
          validateDrsSearchInput: {
            businessAreaId: data.businessArea,
            appealTypeId: data.appealType ? data.appealType : null,
            drsDocumentIdentifierType,
            drsSearchType,
            drsDocumentIdentifierValue: drsDocumentIdentifierValueUpperCase
          }
        }
      })

      if (validateDrsSearchData && !validateDrsSearchValidationError) {
        const docCount = validateDrsSearchData.validateDrsSearch?.documentCount

        if (docCount === 0) {
          return createError({
            path,
            message: formErrorMessages.noDocuments
          })
        } else {
          return true
        }
      } else if (validateDrsSearchValidationError) {
        // There will only be one error in the graphQLErrors collection
        const errorCode =
          validateDrsSearchValidationError.graphQLErrors[0].extensions.code

        if (errorCode === 'DRS_DOCUMENT_IDENTIFIER_NOT_FOUND') {
          return createError({
            path,
            message: formErrorMessages.unknownSearchIdentifier
          })
        } else if (errorCode === 'DRS_SERVER_ERROR') {
          return createError({
            path,
            message: formErrorMessages.drsUnavailable
          })
        } else {
          return true
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const validationSchema = yup.object({
    drsSearchType: yup
      .mixed<DrsSearchType>()
      .oneOf(
        [DrsSearchType.AllDocuments, DrsSearchType.DateRange],
        formErrorMessages.drsSearchTypeRequired
      )
      .required(formErrorMessages.drsSearchTypeRequired)
      .nullable(),
    drsSearchIdentifier: yup
      .string()
      .required(formErrorMessages.selectIdentifier)
      .nullable(),
    nationalInsuranceNumber: yup.string().when('drsSearchIdentifier', {
      is: (drsSearchIdentifier: string) =>
        drsSearchIdentifier === DrsDocumentIdentifierType.Nino,
      then: yup
        .string()
        .required('National Insurance Number cannot be blank')
        .matches(NINO_REGEX, formErrorMessages.invalidNino)
        .test(
          'validateDrsSearchIdentifier',
          async (value, { createError, path }) => {
            return validateDrsSearchValue(
              NINO_REGEX,
              createError,
              path,
              DrsDocumentIdentifierType.Nino,
              value as string
            )
          }
        )
    }),
    customerReferenceNumber: yup.string().when('drsSearchIdentifier', {
      is: (drsSearchIdentifier: string) =>
        drsSearchIdentifier ===
        DrsDocumentIdentifierType.CustomerReferenceNumber,
      then: yup
        .string()
        .required('Customer Reference Number cannot be blank')
        .matches(
          CUSTOMER_REFERENCE_NUMBER_REGEX,
          formErrorMessages.invalidCustomerRefNum
        )
        .test(
          'validateDrsSearchIdentifier',
          async (value, { createError, path }) => {
            return validateDrsSearchValue(
              CUSTOMER_REFERENCE_NUMBER_REGEX,
              createError,
              path,
              DrsDocumentIdentifierType.CustomerReferenceNumber,
              value as string
            )
          }
        )
    }),
    claimReferenceNumber: yup.string().when('drsSearchIdentifier', {
      is: (drsSearchIdentifier: string) =>
        drsSearchIdentifier === DrsDocumentIdentifierType.ClaimReferenceNumber,
      then: yup
        .string()
        .required('Claim Reference Number cannot be blank')
        .matches(
          CLAIM_REFERENCE_NUMBER_REGEX,
          formErrorMessages.invalidClaimRefNum
        )
        .test(
          'validateDrsSearchIdentifier',
          async (value, { createError, path }) => {
            return validateDrsSearchValue(
              CLAIM_REFERENCE_NUMBER_REGEX,
              createError,
              path,
              DrsDocumentIdentifierType.ClaimReferenceNumber,
              value as string
            )
          }
        )
    })
  })

  // Reset values and errors of form when a different search identifier is selected
  const handleSearchIdentifierChange = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any) => void,
    setFieldError: (field: string, message: string) => void
  ) => {
    setFieldValue('nationalInsuranceNumber', '')
    setFieldError('nationalInsuranceNumber', '')

    setFieldValue('customerReferenceNumber', '')
    setFieldError('customerReferenceNumber', '')

    setFieldValue('claimReferenceNumber', '')
    setFieldError('claimReferenceNumber', '')
  }

  const handleDrsSearchTypeChange = async (drsSearchType: string) => {
    setDrsSearchType(drsSearchType as DrsSearchType)
  }

  const handleSubmit = async (
    values: CreateBundleFormData,
    { setSubmitting }: FormikHelpers<CreateBundleFormData>
  ) => {
    setSubmitting(false)

    let isFinalFormStep = false
    let currentStep

    if (values.drsSearchType == DrsSearchType.DateRange) {
      isFinalFormStep = false
      currentStep = 1
    }

    if (
      values.localUploadRequired === 'No' &&
      values.drsSearchType == DrsSearchType.AllDocuments
    ) {
      isFinalFormStep = true
    }

    next(values, isFinalFormStep, currentStep)
  }

  // Reset focus for accessibility screen readers
  useEffect(() => {
    document.getElementById('main-focus')?.focus()
  }, [])

  const conditionalContent = {
    [DrsDocumentIdentifierType.Nino]: (
      <div className={styles.conditionalInput}>
        <InputField
          name="nationalInsuranceNumber"
          label="National Insurance Number"
          hint="For example, ‘QQ123456C’."
        />
      </div>
    ),
    [DrsDocumentIdentifierType.CustomerReferenceNumber]: (
      <div className={styles.conditionalInput}>
        <InputField
          name="customerReferenceNumber"
          label="Customer Reference Number"
        />
      </div>
    ),
    [DrsDocumentIdentifierType.ClaimReferenceNumber]: (
      <div className={styles.conditionalInput}>
        <InputField
          name="claimReferenceNumber"
          label="Claim Reference Number"
        />
      </div>
    )
  }

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        setFieldValue,
        setFieldError,
        isSubmitting,
        isValidating,
        errors
      }) => {
        if (isSubmitting && !isValidating && errors) {
          // If validation errors exist, set focus on first error element
          attachFocusToFirstFormElementError(errors)
        }
        return (
          <div>
            <div className={styles.backLink}>
              <BackLink
                href={''}
                type="button"
                onClick={(e) => {
                  // Prevent reload of create bundle route
                  e.preventDefault()
                  prev(values)
                }}
              >
                Back
              </BackLink>
            </div>

            <Heading size="XLARGE">Search for DRS documents</Heading>
            {validateDrsSearchError &&
              validateDrsSearchError.message ===
                'Drs Search Service unavailable' && (
                <>
                  <ErrorSummaryRetry
                    heading="There is a problem retrieving information from the DRS service"
                    includeBorder={true}
                  />
                </>
              )}

            <Form className={styles.form} name="gather-drs-evidence-form">
              <fieldset className={styles.fieldset}>
                <RadioField
                  name="drsSearchIdentifier"
                  hint="Select one form of identification"
                  label=""
                  radioOptions={[
                    {
                      label: 'National Insurance',
                      value: DrsDocumentIdentifierType.Nino
                    },
                    {
                      label: 'Customer Reference',
                      value: DrsDocumentIdentifierType.CustomerReferenceNumber
                    },
                    {
                      label: 'Claim Reference',
                      value: DrsDocumentIdentifierType.ClaimReferenceNumber
                    }
                  ]}
                  conditionalContent={conditionalContent}
                  onChange={() =>
                    handleSearchIdentifierChange(setFieldValue, setFieldError)
                  }
                />
              </fieldset>
              <div>
                <RadioField
                  name="drsSearchType"
                  hint="Which documents would you like to include in the search?"
                  label=""
                  radioOptions={[
                    {
                      label: 'All documents',
                      value: DrsSearchType.AllDocuments
                    },
                    {
                      label: 'Documents from a date range',
                      value: DrsSearchType.DateRange
                    }
                  ]}
                  onChange={async (event) => {
                    handleDrsSearchTypeChange(event.target.value)
                  }}
                />
              </div>
              <div className={styles.submitButtonGroup}>
                <Button
                  disabled={isSubmitLoading || validateDrsSearchLoading}
                  type="submit"
                >
                  Continue
                </Button>
                {isSubmitLoading ||
                  (validateDrsSearchLoading && (
                    <Spinner
                      className={styles.spinner}
                      width="25px"
                      height="25px"
                    />
                  ))}
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}
